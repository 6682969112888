.side-menu {
  position: fixed;
  top: 68px;
  right: 12px;
  background: transparent;
  transition: all linear 0.2s;
  z-index: -1;
  visibility: hidden;

  .side-menu-container {
    height: 100%;
    background: #fff;
    margin-left: auto;
    border: 1px solid var(--lib-color-dark-100);
    transform: translateX(101%);
    transition: linear 0.2s;

    .nav-item {
      @include lib-txt-4;
      @include lib-txt-weight-600;

      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: 8px;
    }

    .nav-item:first-child {
      margin-top: 12px;
    }

    .nav-item:last-child {
      margin-bottom: 12px;
    }

    .nav-link {
      @include center-children('y');

      color: var(--lib-color-dark-700);
      padding: 12px 24px;

      &:hover {
        color: var(--lib-color-denim-500);
        cursor: pointer;
      }
    }
  }
}

.side-menu.visible {
  visibility: visible;
  background-color: rgba(52, 52, 52, 0.23);
  z-index: 999;

  .side-menu-container {
    transform: translateX(0%);
  }
}

.side-menu.collapse.show {
  visibility: visible;
  background-color: rgba(52, 52, 52, 0.23);
  z-index: 999;

  .side-menu-container {
    transform: translateX(0%);
  }
}

@media (min-width: 576px) {
  //
}

@media (min-width: 768px) {
  //
}

@media (min-width: 992px) {
  //
}

@media (min-width: 1200px) {
  .side-menu.visible {
    z-index: -1;
    visibility: hidden;
    background: transparent;

    .side-menu-container {
      transform: translateX(101%);
    }
  }
}
