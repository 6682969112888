.libitems-profile-banner-wrapper {
    min-height: calc(100% - 468px);

    .libitems-profile-banner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .libitems-profile-banner-cover {
        position: relative;
        width: 100%;
        height: 28vh;
        min-height: 260px;

        &>img {
            object-fit: cover;
        }
    }

    .lib-profile-grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
        margin: 0;
        padding: 0;
        height: auto;

        &.not-visible {
            visibility: hidden;
            height: 0;
            margin: 0;
            padding: 0;
        }

        &.addons-item {
            grid-template-columns: repeat(1, 1fr);
        }

        &.materials-item {
            grid-template-columns: repeat(2, 1fr);
        }

        &.models-item {
            grid-template-columns: repeat(2, 1fr);
        }

        &.textures-item {
            grid-template-columns: repeat(2, 1fr);
        }
        &.blog-item {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .libitems-item-base-container {
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: flex-start;
        //padding: 24px;
        gap: 16px;

        width: 531px;
        height: 531px;
        background: #FFFFFF;
        overflow: hidden;

        &__img-preview:hover {
            transform: scale(1.1);
        }

        &__img-preview {
            width: 531px;
            height: 531px;

            transition: transform 0.3s ease;
            // width: 529px;
            // height: 529px;
            // margin: auto;
        }
    }

    .libitems-item-addons-base-container {
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        width: 600px;
        height: 300px;
        background: #FFFFFF;
        overflow: hidden;

        &__img-preview:hover {
            transform: scale(1.1);
        }

        &__img-preview {
            width: 600px;
            height: 300px;
            transition: transform 0.3s ease;
        }

        .libitems-viewer-wrapper {
            .libitems-viewer-content {
                width: 1900px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
            }

            .libitems-viewer-content-main {
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }

            .libitems-viewer-content-right {
                height: 100%;
            }
        }
    }



    .libitems-profile-socials-and-avatar-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .libitems-profile-socials-and-avatar-container {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.6) 100%);
    }

    .libitems-profile-socials-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 7px;

        .profile-socials-item {
            margin-right: 16px;
            display: none;

            & a {
                color: var(--lib-color-surface-3);
                display: none;
            }

            & svg {
                text-decoration: none !important;

                &:hover {
                    transform: scale(1.1) !important;
                    color: var(--lib-color-denim-500);
                }
            }
        }
    }

    .libitems-profile-avatar-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .libitems-profile__name {
            @include lib-txt-6;
            @include lib-txt-weight-600;

            padding-bottom: 7px;
            margin-top: 8px;
            color: var(--lib-color-surface-3);
        }

        .libitems-profile-avatar {
            width: 112px;
            height: 112px;
            background-color: var(--lib-color-denim-200);
            border: 1px solid var(--lib-color-dark-200);
            color: var(--lib-color-surface-3);

            position: relative;

            &__container {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -18px;
                margin-top: -18px;

                img {
                    position: relative;
                    width: 112px;
                    top: -38px;
                    left: -38px;
                }
            }
        }
    }

    .libitems-profile-main-menu {
        width: 100%;
        background: var(--lib-color-dark-50);
        margin-bottom: 8px;
    }

    .libitems-profile-main-links-container {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        align-items: center;
    }

    .libitems-profile-main-links-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .libitems-profile-main-links__item {
            padding: 11px 10px 11px 10px;
            background: var(--lib-color-dark-50);
            border: none;
            align-items: center;
            color: var(--lib-color-dark-600);

            span {
                @include lib-txt-6;
                @include lib-txt-weight-600;
            }

            &.active {
                border-bottom: 2px solid var(--lib-color-denim-500);
                border-radius: 0;
                color: var(--lib-color-dark-600);
                margin-top: 0;
            }

            &:hover {
                color: var(--lib-color-denim-500);
            }
        }
    }

    .libitems-profile-message-content {
        display: none; // TODO: temporaly hidden 
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 5px;
        width: 90%;

        .libitems-profile-message__item {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            background-color: var(--lib-color-surface);
            border: 1px solid var(--lib-color-dark-100);
            border-radius: 0;
            padding: 7px 11px 8px 11px;
            margin-top: auto;
            margin-bottom: auto;
            color: var(--lib-color-denim-600);
            width: 100%;

            span {
                @include lib-txt-6;
                @include lib-txt-weight-600;

                margin-left: 6px;
            }

            &.active {
                border-bottom: 2px solid var(--lib-color-denim-500);
                border-radius: 0;
            }
        }
    }

    .libitems-profile-main-view {
        min-height: 600px;
    }

    .libitems-profile-main-view-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8px;

        &.not-visible {
            visibility: hidden;
            height: 0;
            opacity: 0;
            transition: visibility 1s, height 1s;
            margin: 0;
            padding: 0;
        }
    }


}

@media (min-width: 768px) {
    .l-lib-profile-main-container {
        padding-left: 32px;
        padding-right: 32px;
    }

    .libitems-profile-banner-wrapper {
        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 28vh;
            min-height: 260px;

            &>img {
                object-fit: cover;
            }
        }

        .libitems-profile-socials-and-avatar-container {
            display: flex;
            align-items: flex-end;
            flex-direction: row;
        }

        .libitems-profile-socials-content {
            padding-bottom: 14px;
        }

        .libitems-profile-avatar-content {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .libitems-profile__name {
                margin-right: 8px;
                padding-bottom: 14px;
                margin-top: 16px;
            }
        }

        .libitems-profile-main-links-content {
            .libitems-profile-main-links__item {
                margin-right: 25px;
            }
        }

        .libitems-profile-main-links-container {
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            align-items: center;
        }

        .libitems-profile-message-content {
            display: none; // TODO: temporaly hidden 
            width: auto;
            margin-left: auto;
            margin-top: 0;
            margin-bottom: 0;
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(1, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(1, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(2, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(2, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(2, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

@media (min-width: 1366px) {
    .l-lib-profile-main-container {
        padding-left: 176px;
        padding-right: 176px;
    }

    .libitems-profile-banner-wrapper {
        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 32vh;
            min-height: 344px;

            &>img {
                object-fit: cover;
            }
        }

        .libitems-profile-avatar-content {

            .libitems-profile__name {
                @include lib-txt-4;
                @include lib-txt-weight-600;

                margin-right: 30px;
                padding-bottom: 14px;
                margin-top: 16px;
                color: var(--lib-color-surface-3);
            }

            .libitems-profile-avatar {
                width: 140px;
                height: 140px;

                position: relative;

                &__container {
                    position: absolute;

                    margin-left: -18px;
                    margin-top: -18px;

                    img {
                        position: relative;
                        width: 140px;
                        top: -52px;
                        left: -52px;
                    }
                }
            }
        }

        .libitems-profile-message-content {
            display: none; // TODO: temporaly hidden 
            width: auto;
            .libitems-profile-message__item {
                padding: 13px 15px 13px 16px;

                span {
                    @include lib-txt-4;
                    @include lib-txt-weight-600;

                    margin-left: 6px;
                }
            }
        }

        .libitems-profile-main-links-content {
            .libitems-profile-main-links__item {
                padding: 17px 10px 17px 10px;
                margin-right: 35px;

                span {
                    @include lib-txt-4;
                    @include lib-txt-weight-600;
                }
            }
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(2, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(1, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media (min-width: 1900px) {
    .l-lib-profile-main-container {
        padding-left: 270px;
        padding-right: 270px;
    }

    .libitems-profile-banner-wrapper {
        .libitems-profile-main-menu {
            margin-bottom: 20px;
        }

        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 40vh;
            min-height: 440px;

            &>img {
                object-fit: cover;
            }
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(4, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(3, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(6, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media screen and (min-width: 2300px) {
    .libitems-profile-banner-wrapper {
        .libitems-profile-main-menu {
            margin-bottom: 20px;
        }

        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 40vh;
            min-height: 440px;

            &>img {
                object-fit: cover;
            }
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(5, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(3, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .libitems-profile-socials-and-avatar-container {
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        }
    }
}


@media screen and (min-width: 2560px) {
    .libitems-profile-banner-wrapper {
        .libitems-profile-main-menu {
            margin-bottom: 20px;
        }

        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 40vh;
            min-height: 440px;

            &>img {
                object-fit: cover;
            }
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(5, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(5, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

@media screen and (min-width: 3200px) {
    .libitems-profile-banner-wrapper {
        .libitems-profile-main-menu {
            margin-bottom: 20px;
        }

        .libitems-profile-banner-cover {
            position: relative;
            width: 100%;
            height: 40vh;
            min-height: 440px;

            &>img {
                object-fit: cover;
            }
        }

        .lib-profile-grid-container {
            grid-template-columns: repeat(6, 1fr);
            width: auto;

            &.addons-item {
                grid-template-columns: repeat(5, 1fr);
            }

            &.materials-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.models-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.textures-item {
                grid-template-columns: repeat(10, 1fr);
            }

            &.blog-item {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
}