.libitems-legal-page-wrapper {
    display: flex;
    width: auto;
    flex-direction: column;
    min-height: calc(100% - 384px);
    gap: 2rem; // Add spacing between flex items
}

.blog-title {
    margin-bottom: 1rem;

    h4 {
        @include lib-txt-3;
        @include font-family;
        @include lib-txt-weight-600;
        color: var(--lib-color-dark-700);
    }
}

.legal-list {
    h6 {
        margin-bottom: 1.5rem;
        margin-left: 1rem;

    }

    a {
        @include lib-txt-4;
        @include font-family;
        color: var(--lib-color-dark-500);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.blog-summary {
    @include lib-txt-4;
    @include font-family;
    color: var(--lib-color-dark-500);
}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1366px) {}

@media screen and (min-width: 1900px) {}

@media screen and (min-width: 2560px) {}