$inter-medium-font-family: 'Inter Medium';
$inter-light-font-family: 'Inter Light';
$inter-italic-font-family: 'Inter Italic';
$inter-regular-font-family: 'Inter Regular';
$inter-font-family: 'Inter';

$main-color: #1f77c9;
$main-backgroundColor: #fff;
$main-header-height: 62px;

$categories-menu-width: 17.5rem; //280px
$main-view-width: calc(100% - 17.5rem); // 100%-categories-menu-width

@mixin flex-direction($direction) {
  @if $direction == column {
    //-webkit-flex-direction: vertical;
    -moz-flex-direction: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  @else {
    //-webkit-flex-direction: horizontal;
    -moz-flex-direction: horizontal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@mixin center-children($axis: "both") {
  @if $axis == "y" {
    display: flex;
    align-items: center;
  }

  @if $axis == "x" {
    display: flex;
    justify-content: center;
  }

  @if $axis == "both" {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin hover-supports {
  //created by modernizr in html tag
  .no-touchevents {
    @content;
  }
}

@mixin ie10-and-higher {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin scrollbar {
  scrollbar-color: #c8cfdc unset;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #c8cfdc;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
}

@mixin scrollbar-invisible {
  scrollbar-color: transparent;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  &:focus {
    outline: 0;
  }
}

.lib-scrollbar {
  @include scrollbar();
}

.lib-scrollbar-invisible {
  @include scrollbar-invisible();
}

.lib-top-0 {
  top: 0;
}

.lib-left-0 {
  left: 0;
}

.lib-bottom-0 {
  bottom: 0;
}

.lib-right-0 {
  right: 0;
}

.lib-center-child-x {
  @include center-children('x');

  flex-direction: column;
}

.lib-center-child-y {
  @include center-children('y');

  flex-direction: row;
}

.lib-center-child {
  @include center-children;
}
