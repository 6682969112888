.modal-fullscreen-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.modal-full-screen-btn {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    z-index: 2;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: transparent;
    stroke: white;
    stroke-width: 1.5px;
    border-radius: 0;
    &:hover {
        background-color: #242424;
        border-color: #242424;
        transform: scale(1.1);
    }
}
.modal-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: transparent;
    border: 0;
    border-radius: 50px;
    width: 64px;
    height: 64px;
    stroke: white;
    stroke-width: 1px;
    fill: none;
    padding: 0;

    &:hover {
        background-color: #242424;
        border-color: #242424;        
        transform: translate(-50%, -50%) scale(1.1);
    }
    &.not-visible {
        visibility: hidden;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.modal-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 64px;
    height: 64px;
}

.howtouse-wrapper {
    display: flex;
    //justify-content: center;
    flex-direction: column;
    min-height: calc(100% - 468px);
    align-items: center;

    &.block-full-height {        
        min-height: fit-content;
        display: block;
        padding: 1rem 1rem;
    }

    .howtouse-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        padding-top: 20px;        
        
        &.block-full-height {            
            min-height: fit-content;
            display: block;
            padding: 1rem 1rem;
        }
       
        //this selector only for html that is diplayed in Libitems App
        &.block-full-height.client {            
            min-height: fit-content;
            display: block;
            padding: 1rem 1rem;
            width: 900px;

            .howtouse-body {
                width: 100%;
                height: auto;
                //background-color: blue;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
    
                &__title {
                    width: 100%;
                    height: auto;
                    //order: 0;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;
                    /* identical to box height */
    
                    letter-spacing: -0.05em;
    
                    /* On surface/700 */
    
                    color: #162842;
                    padding: 10px;
                }
        
                .howtouse-body__content {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 10px;
                    max-height: 506px;
    
                    &__video {
                        width: 100%;
                        height: 506px;
                    }
                    
                    &__image {
                        max-width: 100%;
                        height: auto;
                        max-height: 506px;
                    }

                    .modal-viewer {
                        position: relative;                        
                        .image-preview {
                            max-width: 100%;
                            height: auto;
                            max-height: 506px;
                        }                        
                    }
                }
    
                &__manual-link {
                    width: 100%;
                    height: 50px;
                    //order: 2;
                    padding: 10px;
                    //background-color: burlywood;
    
                    a {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
    
                        /* On surface/500 */
    
                        color: var(--lib-color-dark-500);
                    }
                }
    
                a:hover {
                    color: var(--lib-color-denim-600);
                }
    
                &__short-text {
                    width: 100%;
                    height: auto;
                    //order: 3;
                    padding: 10px;
                    //background-color: aquamarine;
    
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 128.02%;
                    /* or 23px */
    
    
                    /* On surface/600 */
    
                    color: #31425A;
    
    
                    /* Inside auto layout */
    
                    flex: none;
                    align-self: stretch;
                }
    
            }


        }

        .howtouse-body {
            width: 100%;
            height: auto;
            //background-color: blue;
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            &__title {
                width: 100%;
                height: auto;
                //order: 0;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                /* identical to box height */

                letter-spacing: -0.05em;

                /* On surface/700 */

                color: #162842;
                padding: 10px;
            }

            .howtouse-body__content {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 10px;
                max-height: 220px;

                &__video {
                    width: 100%;
                    height: 220px;
                }

                &__image {
                    max-width: 100%;
                    height: auto;
                    max-height: 220px;
                }

                .modal-viewer {                    
                    position: relative;
                    .image-preview {
                        max-width: 100%;
                        height: auto;
                        max-height: 220px;
                    }
                }

                
            }

            &__manual-link {
                width: 100%;
                height: 50px;
                //order: 2;
                padding: 10px;
                //background-color: burlywood;

                a {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;

                    /* On surface/500 */

                    color: var(--lib-color-dark-500);
                }
            }

            a:hover {
                color: var(--lib-color-denim-600);
            }

            &__short-text {
                width: 100%;
                height: auto;
                //order: 3;
                padding: 10px;
                //background-color: aquamarine;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 128.02%;
                /* or 23px */


                /* On surface/600 */

                color: #31425A;


                /* Inside auto layout */

                flex: none;
                align-self: stretch;
            }

        }
    }
}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1366px) {

    .howtouse-wrapper {
        .howtouse-page-content {
            width: 900px;

            .howtouse-body {
                .howtouse-body__content {
                    max-height: 506px;

                    &__video {
                        height: 506px;
                    }
                    
                    &__image {
                        max-height: 506px;
                    }

                    .modal-viewer {                        
                        .image-preview {
                            max-height: 506px;
                        }
                    }

                }
            }
        }
    }
}

@media screen and (min-width: 1900px) {}

@media screen and (min-width: 2560px) {}