:root {
  /* denim */
  --lib-color-denim-200: #eff7fe;
  --lib-color-denim-400: #4aa8ff;
  --lib-color-denim-500: #0c89fd;
  --lib-color-denim-600: #1f77c9;

  /* light */
  --lib-color-surface: #fff;
  --lib-color-surface-1: #f9f9f9;
  --lib-color-surface-3: #f4f4f4;
  --lib-color-surface-4: #eaeaea;
  --lib-color-surface-5: #cacaca;

  /* dark */
  --lib-color-dark-50: #ecf1f7;
  --lib-color-dark-100: #d3dde7;
  --lib-color-dark-200: #a9b6c3;
  --lib-color-dark-300: #929eb1;
  --lib-color-dark-400: #788496;
  --lib-color-dark-500: #667286;
  --lib-color-dark-600: #31425a;
  --lib-color-dark-700: #162842;
  --lib-color-dark-800: #041626;

  /* gray */
  --lib-color-gray-37: #5e5e5e;
  --lib-color-gray-41: #686868;
  --lib-color-gray-44: #6f6f6f;
  --lib-color-gray-71: #b5b5b5;
  --lib-color-gray-87: #dedede;

    /* success */
    --lib-color-success1: #00b94a;
    --lib-color-success2: #e0faea;

  /* critical */
  --lib-color-critical1: #b62828;
  --lib-color-critical: #ea4646;
  /* warning */
  --lib-color-dark-alert1: #6d6200;
  --lib-color-dark-alert2: #e8d956;
  --lib-color-dark-alert3: #fffbd9;
  --lib-color-white: #e5e5e5;
  --lib-color-title: #062047;
  --lib-color-button-border: #0c89fd;

  /* semantic color */
  --lib-color-primary: var(--lib-color-denim-600);
  --lib-color-background-primary: var(--lib-color-white);
  --lib-color-scrollbar: var(--lib-color-denim);
  --lib-color-error: var(--lib-color-critical);
  --lib-color-black: var(--lib-color-dark-800);
  --lib-color-text-grey: var(--lib-color-denim);
  --lib-color-icon: var(--lib-color-denim-600);
}
