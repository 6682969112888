.blog-page-wrapper {
    display: flex;
    //justify-content: center;
    width: auto;
    flex-direction: column;
    min-height: calc(100% - 29.25rem);

    .blog-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        padding-top: 1.25rem;
        width: auto;


        .blog-grid-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 0;
            margin: 0;
            padding: 0;
            height: auto;

            &.not-visible {
                visibility: hidden;
                height: 0;
                margin: 0;
                padding: 0;
            }
        }

        .load-more-container {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0.5rem;

            &.not-visible {
                visibility: hidden;
                height: 0;
                opacity: 0;
                transition: visibility 1s, height 1s;
                margin: 0;
                padding: 0;
            }
        }
    }



    .blog-page-body-wrapper {

        width: 100%;
        height: auto;
        min-height: 10.875rem;

        background: #ECF1F7;
        order: 0;
        display: flex;
        //justify-content: center;
        flex-direction: row;

        .blog-page-body-content {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            order: 1;
            margin-left: 5%;
            margin-top: auto;
            margin-bottom: auto;

            &__user {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 3.125rem;

                &__user-preview {
                    width: 3.125rem;
                    height: 3.125rem;
                    order: 0;

                    img {
                        width: 3.125rem;
                        height: 3.125rem;
                    }
                }

                &__user-name {
                    width: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: var(--lib-color-dark-500);
                    order: 1;
                    padding-left: 0.625rem;
                    margin-top: 1rem;
                }

                &__date-last-update {
                    width: auto;
                    height: 1.875rem;
                    //background-color: rgb(132, 255, 0);
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: var(--lib-color-dark-500);
                    margin-top: 1rem;
                    order: 2;
                    padding-left: 0.625rem;
                }
            }



            &__article-name {
                width: 100%;
                min-height: 3.125;
                height: auto;
                //background-color: rgb(221, 255, 0);
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.5rem;
                letter-spacing: -0.05rem;
                color: var(--lib-color-dark-700);
            }
        }
    }
}

.blog-item-base-container,
.blog-item-discover-container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1rem;

    width: 22.5rem;
    height: 27.75rem;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    margin-right: 1rem;
    margin-bottom: 1rem;

    &.compact {
        height: 19.25rem;
    }

    &.compact-home {
        height: 13.25rem;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    &__preview-link {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>img {
            max-height: 5.7rem;
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__preview {        
        height: 5.7rem;
        top: 0;
    }

    &__date-public {
        height: 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1rem;
        color: #062047;
    }

    &__name {
        height: auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.875rem;
        color: #062047;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;

        color: #5E5E5E;
        hyphens: auto;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.compact-home {
            -webkit-line-clamp: 4;
        }
    }
}


.lib-article {
    max-width: 53.125rem;
    margin: 0 0.5rem 0 0.5rem;
}

.lib-article * {
    // @include lib-txt-6;
    // @include lib-txt-weight-400;
    color: var(--lib-color-dark-600);
}

.lib-article :is(h1, h2, h3, h4, h5, h6) {
    @include lib-txt-4;
    @include lib-txt-weight-500;
    margin-top: 1.5rem;
    color: var(--lib-color-dark-700);
}

.lib-article p {
    @include lib-txt-6;
    @include lib-txt-weight-400;
    margin: 0.5rem 0;
    line-height: 128%;
}

@media (min-width: 768px) {
    .blog-page-wrapper {
        .blog-page-content {
            .blog-grid-container {
                grid-template-columns: repeat(1, 1fr);
                width: auto;
            }
        }
    }

    .blog-item-base-container,
    .blog-item-discover-container {
        width: 33rem;
        
        &__preview-link {    
            &>img {
                max-height: 8.5rem;
                
            }
        }
    
        &__preview {        
            height: 8.5rem;            
        }
    }

    .blog-wrapper {}

    .lib-article {
        margin: 0 0 1rem 0;
    }

    .lib-article p {
        @include lib-txt-4;
        @include lib-txt-weight-400;
        margin: 0.5rem 0;
        line-height: 128%;
    }

    .lib-article :is(h1, h2, h3, h4, h5, h6) {
        @include lib-txt-3;
        @include lib-txt-weight-500;
        margin-top: 1.5rem;
        color: var(--lib-color-dark-700);
    }
}

@media screen and (min-width: 1366px) {
    .blog-page-wrapper {
        .blog-page-content {
            .blog-grid-container {
                grid-template-columns: repeat(2, 1fr);
                width: auto;
            }
        }
    }

    .blog-wrapper {}

    .blog-item-discover-container {
        width: 29rem;
    }
}

@media screen and (min-width: 1900px) {
    .blog-page-wrapper {
        .blog-page-content {
            .blog-grid-container {
                grid-template-columns: repeat(3, 1fr);
                width: auto;
            }
        }

        .blog-page-body-wrapper {
            .blog-page-body-content {
                margin-left: 7.8rem;
            }
        }
    }

    .blog-wrapper {}

    .blog-item-discover-container {
        width: 29rem;
    }
}

@media screen and (min-width: 2560px) {
    .blog-page-wrapper {
        .blog-page-content {
            .blog-grid-container {
                grid-template-columns: repeat(4, 1fr);
                width: auto;
            }
        }
    }

    .blog-wrapper {}
    
    .blog-item-base-container,
    .blog-item-discover-container {
        width: 30.5rem;

        &__preview-link {           
    
            &>img {
                max-height: 7.7rem;
                width: 100%;
            }
        }
        &__preview {            
            height: 7.7rem;            
        }
    }
}

@media screen and (min-width: 3200px) {
    .blog-page-wrapper {
        .blog-page-content {
            .blog-grid-container {
                grid-template-columns: repeat(6, 1fr);
                width: auto;
            }
        }
    }

    .blog-wrapper {}

    .blog-item-discover-container {
        width: 30rem;

        &__preview-link {           
    
            &>img {
                max-height: 9.5rem;
                width: 100%;              
    
                
            }
        }
    }
}