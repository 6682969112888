.lib-tree-list {
    margin-top: 0;
    padding-left: 0;
    width: 100%;
    border-bottom: 1px solid var(--lib-color-gray-87);
}
.lib-tree-items-list {
    padding-left: 0;
    list-style: none;
    width: 100%;

}
.lib-tree-level {
    display: flex;
}

.lib-tree-item {
    @include lib-txt-6;
    @include lib-txt-weight-400;
    width: 100%;
    display: flex;

    padding: 0.5rem 0.25rem 0.5rem 0.25rem;
    margin: 0;
    //margin-bottom: 10px;
    border:0;
    position: relative;
    background: var(--lib-color-surface);
    color: var(--lib-color-dark-700); 

      // Hover state
  &:hover {
    z-index: 1; // Place hover/focus items above their siblings for proper border styling
    color: $list-group-action-hover-color;
    text-decoration: none;
    background-color: $list-group-hover-bg;
    color: var(--lib-color-primary);

    & * {
        color: var(--lib-color-primary);
      }
  }

  &:active,
  &:focus {
    color: var(--lib-color-button-border);
    background-color: var(--lib-color-denim-200);
    
  }

    &__count {
        @include lib-txt-weight-400;
        margin-left: 8px;
        color: var(--lib-color-dark-300);
    }
    
    &__top {        
        @include lib-txt-weight-500;

        & > .lib-tree-list-open,
        &.collapsed > .lib-tree-list-close {           
            display: none;            
        }
        & > .lib-tree-list-close,
        &.collapsed > .lib-tree-list-open {           
            display: flex;            
        }
    }

    &__most-top {
        @include lib-txt-4;
        @include lib-txt-weight-600;

        margin: 0;
        margin-bottom: 20px;
        
        & > .lib-tree-list-open,
        &.collapsed > .lib-tree-list-close {           
            display: none;            
        }
        & > .lib-tree-list-close,
        &.collapsed > .lib-tree-list-open {           
            display: flex;            
        }      
    }
}

.indent {
    margin-left:8px;
    margin-right: 8px;
}

.lib-tree-list-open,
.lib-tree-list-close {
    @include center-children('y');
    margin-left: auto;
}