//all libitems styles starts with 'lib-'

//we use same principe as  bootstrap for media
//No media query for mobile since this is the default in Bootstrap
//that mean we define  our styles  for mobile  devicres first , then by @media keyword  we  determine for all devices (desctop, tablet etc..)

//use  such media resolution:

// Small devices (landscape phones, 576px and up)

/* @media (min-width: 576px) {
    ...
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    ...
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    ...
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    ...
} */

.libitems-app-wrapper {
  position: relative;

  .libitems-app-content {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .app-content-container {
    max-width: 1928px;
    margin: auto;
    padding-left: 22px;
    padding-right: 22px;
  }

  .libitems-app-cover-wrapper {
    order: 0;
    margin-bottom: 24px;
    padding-right: 0;

    .libitems-app-cover-content {
      width: 100%;
    }

    .libitems-app-cover-content img {
      border-radius: 0;
    }
  }

  .libitems-app-info-wrapper {
    order: 1;
    padding-right: 0;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .libitems-app-version-container {
    margin-bottom: 16px;

    @include lib-txt-weight-600;

    .libitems-app-version {
      display: none;
      width: fit-content;
      background: var(--lib-color-dark-alert3);
      padding: 16px 24px;
    }
  }

  .libitems-app-title {
    margin-bottom: 32px;

    @include lib-txt-weight-600;
  }

  .libitems-app-instruction {
    margin-bottom: 24px;

    @include lib-txt-weight-600;

    .lib-icon {
      opacity: 1;
      color: $main-color;
    }
  }

  .libitems-app-action-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      padding-left: 24px;
      padding-right: 24px;
      flex-wrap: nowrap;
      margin-right: 32px;
    }
  }

  .libitems-app-os-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 54px;

    .libitems-app-os-item {
      padding-right: 32px;
      padding-left: 32px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      color: var(--lib-color-gray-71);
      align-items: center;
      border-right: 1px solid var(--lib-color-gray-71);

      span {
        margin-top: 14px;
      }
    }
  }

  .libitems-app-os-item:first-child {
    padding-left: 0;
  }

  .libitems-app-os-item:last-child {
    padding-right: 0;
    border: 0;
  }
}

.libitems-discover-wrapper {
  position: relative;

  .libitems-discover-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 40px;
  }

  .libitems-discover-title-wrapper {
    @include lib-txt-weight-600;

    margin-bottom: 45px;
  }

  .libitems-discover-itemsaddon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .libitems-discover-items-wrapper {
    order: 1;
  }

  .libitems-discover-addons-wrapper {
    order: 0;
    margin-bottom: 16px;
  }

  .libitems-discover-items-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    margin: 0;
    padding: 0;
  }

  .addons-item-home {
    max-width: 300px;
  }

  .home-item-preview-link,
  .home-items-addon-preview-link {
    overflow: hidden;

    &>img {
      max-height: 176px;
      width: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    &:hover > .bottom-label-item {    
      color: #FFFFFF; // rgba(255, 255, 255, .4);
      position: absolute;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      vertical-align: middle;
      left: 0;
      bottom: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      background: rgba(0, 0, 0, 0.5);
      z-index: 3;
      width:100%;
      height: 3.75em;
      -webkit-transform: translate3d(0,0,0);
	    transform: translate3d(0,0,0);
      hyphens: auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:hover > .bundle-container-item > .corner {      
      opacity: 0;
    }
    &:hover > .bundle-container-item > .trapezoid {
      opacity: 1;
      transition: opacity 0.35s ease-in-out;
    }

    .corner {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
      width: 0;
      height: 0;
      border-top: 36px solid var(--lib-color-primary);
      border-right: 36px solid transparent;
      & span {
        position: absolute;
        top: 0;
        left: 6px;      
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;          
        z-index: 4;
      }
    }
  
    .trapezoid {
      opacity: 0;
      width: 100px;
      height: 0;      
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;
      border-bottom: 24px solid var(--lib-color-primary);
      transform: rotate(-45deg);
      position: absolute;
      left: -23px;
      top:15px;
  
      & span {
        position: absolute;
        top: 0;
        left: 6px;      
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;          
        z-index: 4;
      }   
    } 
  }

  .libitems-discover-addon-preview-thumbnail {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 22px;
    max-width: 402px;
    cursor: pointer;
  }

.discover-item-preview-thumbnail{
  max-width: 282px;
}

  .libitems-discover-addons-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    margin: 0;
    padding: 0;
    padding-top: 16px;
  }

  .libitems-discover-more-wrapper {
    margin-top: 22px;
  }
}

.libitems-market-info-wrapper {
  .libitems-market-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 48px;
  }

  .libitems-market-info-cover {
    position: relative;
    width: 100%;
    height: 90vh;
    min-height: 600px;

    & > img {
      object-fit: cover;
    }
  }

  .libitems-market-info-title {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    color: var(--lib-color-surface);
    transform: translate(-40%, -50%);

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > p {
        margin-bottom: 4px;
      }

      & > a {
        margin-top: 24px;
      }
    }
  }
}

.libitems-features-wrapper {
  margin-bottom: 8px;

  .libitems-features-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 64px;
  }

  .libitems-features-info-wrapper {
    order: 1;
  }

  .libitems-features-cover-wrapper {
    order: 0;
  }

  .libitems-features-info-title {
    padding: 0 1rem;
    color: var(--lib-color-dark-700);

    & > p {
      @include lib-txt-weight-600;
    }

    &__desc {
      @include lib-txt-weight-600;

      color: var(--lib-color-dark-500);
    }
  }

  .libitems-features-body {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 1rem;

    .libitems-features-item {
      border: 1px solid #d4d9de;
      //width: 489px;
      height: 201px;
      padding: 21px 16px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      & > svg {
        color: var(--lib-color-denim-500);
      }

      &__title {
        color: (--lib-color-dark-700);
        margin-top: 16px;
        margin-bottom: 10px;
      }

      &__text {
        color: (--lib-color-dark-600);
      }
    }
  }
}

.libitems-blog-wrapper {
  background: #f0f4fd;

  .libitems-blog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin: 0;

    &__top {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__body {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 24px;
    }
  }

  .libitems-blog-home-container {
    display: flex;
    flex-direction: column;

    &__items {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 0px;
      margin: 0;
      padding: 0;
    }
  }

  .libitems-blog-title {
    @include lib-txt-weight-600;

    color: var(--lib-color-dark-600);
  }

  .libitems-blog-all-articles {
    @include lib-txt-weight-600;

    margin-left: auto;

    & > a {
      color: var(--lib-color-denim-600);
      text-decoration: none;
    }
  }

  .libitems-blog-article {
    width: 531px;
    height: 212px;
    background: #fff;
    border: 1px solid #dedede;
    padding: 24px;

    &__top-news {
      width: 531px;
      height: 444px;
      background: #fff;
      border: 1px solid #dedede;
      padding: 24px;
    }

    &__thumnail {
      margin-top: 40px;
      margin-bottom: 16px;
    }

    &__date {
      @include font-family;

      color: var(--lib-color-title);
    }

    &__title {
      @include lib-txt-weight-600;

      color: var(--lib-color-dark-600);
      margin-top: 8px;
    }

    &__short-text {
      @include font-family;

      color: var(--lib-color-gray-37);
      margin-top: 16px;
    }
  }
}

@media (min-width: 576px) {
  //
}

@media (min-width: 768px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .app-content-container {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .libitems-features-wrapper {
    .libitems-features-body {
      padding: 0;
      .libitems-features-item {
        width: 489px;
      }
    }

    .libitems-features-info-title{
      padding: 0;
    }
  }

  .libitems-discover-wrapper {
    .libitems-discover-items-wrapper {
      order: 0;
    }

    .libitems-discover-addons-wrapper {
      order: 1;
    }

    .libitems-discover-items-content {
      grid-template-columns: repeat(6, 1fr);
    }

    .addons-item-home {
      max-width: 368px;
    }

    .home-item-preview-link {
      &>img {
        max-height: 176px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }

    .home-items-addon-preview-link {
      &>img {
        max-height: 186px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }

    .libitems-discover-addons-content {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .libitems-blog-wrapper {
    .libitems-blog-content {
      padding: 64px;
    }    
  }
}

@media (min-width: 992px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      flex-direction: row;
      padding-top: 57px;
      padding-bottom: 57px;
    }

    .app-content-container {
      padding-left: 31px;
      padding-right: 31px;
    }

    .libitems-app-action-container {
      display: block;
    }

    .libitems-app-info-wrapper {
      width: 50%;
      padding-right: 60px;
      order: 0;
    }

    .libitems-app-cover-wrapper {
      @include center-children();

      padding-left: 10px;
      width: 50%;
      order: 1;
    }

    .libitems-app-os-container {
      display: inline-flex;
    }
  }

  .libitems-features-wrapper {
    margin-bottom: 162px;

    .libitems-features-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin-top: 197px;
    }

    .libitems-features-info-wrapper {
      width: 40%;
      margin-right: 120px;
      order: 0;
    }

    .libitems-features-cover-wrapper {
      display: flex;
      width: 60%;
      order: 1;

      .img-fluid {
        max-width: 100%;
        height: auto;
        margin-left: auto;
    }
    }

    .libitems-features-info-content {
      margin-left: 163px;
    }

    .libitems-features-info-title {
      &__desc {
        margin-top: 18px;
      }

      &__action {
        margin-top: 18px;
      }
    }

    .libitems-features-body {
      margin-top: 48px;
    }
  }

  .libitems-blog-wrapper {
    .libitems-blog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 132px;
      margin: 0;

      &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
      }

      &__body {
        margin-top: 42px;
      }
    }

    .libitems-blog-home-container {
      display: flex;
      flex-direction: row;

      &__top-news {
        margin-right: 24px;
      }

      &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 0px;
        column-gap: 24px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width: 1366px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .app-content-container {
      padding-left: 31px;
      padding-right: 31px;
    }
  }

  .libitems-discover-wrapper {
    .addons-item-home {
      max-width: 420px;
    }

    .home-item-preview-link {
      &>img {
        max-height: 202px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }

    .home-items-addon-preview-link {
      &>img {
        max-height: 212px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }
  }
}


@media (min-width: 1900px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .app-content-container {
      padding-left: 121px;
      padding-right: 121px;
    }
  }

  .libitems-discover-wrapper {
    .addons-item-home {
      max-width: 568px;
    }

    .home-item-preview-link {
      &>img {
        max-height: 276px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        padding-top: 0.2em;
    }
    }

    .home-items-addon-preview-link {
      &>img {
        max-height: 290px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        padding-top: 0.2em;
    }
    }
  }
}

@media (min-width: 2560px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .app-content-container {
      padding-left: 256px;
      padding-right: 256px;
    }
  }

  .libitems-discover-wrapper {
    .addons-item-home {
      max-width: 700px;
    }

    .home-item-preview-link {
      &>img {
        max-height: 342px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    }


    .home-items-addon-preview-link {
      &>img {
        max-height: 350px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    }
  }
}

@media (min-width: 3840px) {
  .libitems-app-wrapper {
    .libitems-app-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .app-content-container {
      padding-left: 256px;
      padding-right: 256px;
    }
  }
}
