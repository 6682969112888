.features-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100% - 468px);

    .features-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        padding-top: 20px;
        width: auto;

        .features-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            justify-content: center;
            padding-top: 20px;
            width: auto;

            &.reverse {
                flex-direction: column;
            }

            &__icon {
                display: flex;
                width: auto;
                height: 450px;


                img {
                    width: 350px;
                    margin: auto;

                }
            }

            &__text {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 20px;


                &__title {
                    width: auto;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 110.52%;

                    letter-spacing: -0.05em;
                    color: var(--lib-color-dark-700);
                    padding-bottom: 20px;
                    padding-left: 10px;
                    padding-right: 10px;
                    hyphens: auto;
                    display: block;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                &__text-info {
                    width: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 10px;
                    padding-right: 10px;
                    color: var(--lib-color-dark-600);
                    hyphens: auto;
                    display: block;
                    -webkit-line-clamp: 10;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }


            }
        }
    }

    .features-footer-wrapper {
        width: 100%;
        height: auto;
        min-height: 174px;

        background: var(--lib-color-dark-50);
        order: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__info-download {
            display: flex;
            justify-content: center;
            flex-direction: column;            
            height: 200px;
            order: 1;

            &__title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 110.52%;

                letter-spacing: -0.05em;
                color: var(--lib-color-dark-700);
                order: 1;
            }

            &__bth {
                width: 100%;
                height: 48px;
                order: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__logo-app {
            display: flex;
            justify-content: center;
            width: 386px;
            height: 386px;

            order: 0;

            img {
                width: 365px;

            }

        }
    }
}

@media screen and (min-width: 768px) {
    .features-wrapper {
        .features-page-content {
            .features-container {
                flex-direction: column;

                &.reverse {
                    flex-direction: column;
                }

                &__icon {
                    width: auto;
                    height: 450px;

                    img {
                        width: 350px;
                    }
                }

                &__text {
                    height: auto;
                    align-items: center;
                    justify-content: center;
                    width: auto;                    

                    &__title {
                        hyphens: auto;
                        display: -webkit-box;
                    }

                    &__text-info {
                        display: -webkit-box;
                    }
                }
            }
        }
        .features-footer-wrapper {
            flex-direction: column;
    
            &__info-download {
                width: 500px;
                height: 200px;
                order: 1;
        
                &__title {

                }
    
                &__bth {

                }
            }
    
            &__logo-app {
                width: 386px;
                height: 386px;
    
                order: 0;
    
                img {
                    width: 365px;
    
                }
    
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .features-wrapper {
        .features-page-content {
            .features-container {
                flex-direction: column;

                &.reverse {
                    flex-direction: column;
                }

                &__icon {
                    width: 427px;
                    height: 450px;

                    img {
                        width: 350px;
                    }
                }

                &__text {
                    height: auto;
                    align-items: center;
                    justify-content: center;
                    width: 480px;

                    &__title {}

                    &__text-info {
                        width: 480px;
                    }
                }
            }
        }
        .features-footer-wrapper {
            flex-direction: column;
    
            &__info-download {
                width: 500px;
                height: 400px;
                order: 1;

        
                &__title {

                }
    
                &__bth {

                }
            }
    
            &__logo-app {                
                height: 486px;
    
                order: 0;
    
                img {
                    max-width: 100%;
    
                }
    
            }
        }
    }
}

@media screen and (min-width: 1900px) {
    .features-wrapper {
        .features-page-content {
            .features-container {
                flex-direction: row;

                &.reverse {
                    flex-direction: row-reverse;
                }

                &__icon {
                    width: 427px;
                    height: 680px;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 427px;
                    }
                }

                &__text {
                    height: auto;
                    align-items: center;
                    justify-content: center;
                    width: 480px;
                    height: 680px;


                    &__title {
                        align-items: start;
                    }

                    &__text-info {
                        width: 480px;
                    }
                }
            }

        }
        .features-footer-wrapper {
            flex-direction: row;
    
            &__info-download {
                width: 500px;
                height: 400px;
                order: 0;

        
                &__title {

                }
    
                &__bth {

                }
            }
    
            &__logo-app {
                width: 486px;
                height: 486px;
    
                order: 1;
    
                img {
                    width: 465px;
    
                }
    
            }
        }
    }
}

@media screen and (min-width: 2560px) {
    .features-wrapper {
        .features-page-content {
            .features-container {
                flex-direction: row;

                &.reverse {
                    flex-direction: row-reverse;
                }

                &__icon {
                    width: 427px;
                    height: 680px;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 427px;
                    }
                }

                &__text {
                    height: auto;
                    align-items: center;
                    justify-content: center;
                    width: 550px;
                    height: 680px;

                    &__title {
                        align-items: start;
                    }

                    &__text-info {
                        width: 550px;

                    }
                }
            }

        }
        .features-footer-wrapper {
            flex-direction: row;
    
            &__info-download {
                width: 500px;
                height: 400px;
                order: 0;

        
                &__title {

                }
    
                &__bth {

                }
            }
    
            &__logo-app {
                width: 486px;
                height: 486px;
    
                order: 1;
    
                img {
                    width: 465px;
    
                }
    
            }
        }
    }
}