/* all libitems styles starts with 'lib-'

we use same principe as  bootstrap for media
No media query for mobile since this is the default in Bootstrap
that mean we define  our styles  for mobile  devicres first,
then by @media keyword  we  determine for all devices (desctop, tablet etc..)

use  such media resolution:

Small devices (landscape phones, 576px and up)
/* @media (min-width: 576px) {
  ...
}

Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  ...
}

Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  ...
}

Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    ...
} */

#lib-header {
  height: $main-header-height;
  width: 100%;
  border-bottom: 1px solid var(--lib-color-gray-87);
}

#lib-footer {
  width: 100%;
  background: #fff;
}

.l-lib-content-container,
.xl-lib-content-container {
  max-width: 1928px;
  margin: auto;
}

.l-lib-header-container,
.l-lib-footer-container,
.l-lib-content-container {
  padding-left: 22px;
  padding-right: 22px;
}

.xl-lib-content-container {
  padding-left: 12px;
  padding-right: 12px;
}

#lib-main-content {
  position: relative;
  min-height: 87vh;
}

@media (min-width: 768px) {
  .l-lib-header-container,
  .l-lib-footer-container,
  .l-lib-content-container {
    padding-left: 8px;
    padding-right: 8px;
  }

  .xl-lib-content-container {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media (min-width: 1366px) {
  .l-lib-header-container,
  .l-lib-footer-container,
  .l-lib-content-container {
    padding-left: 31px;
    padding-right: 31px;
  }
}


@media (min-width: 1900px) {
  .l-lib-header-container,
  .l-lib-footer-container,
  .l-lib-content-container {
    padding-left: 121px;
    padding-right: 121px;
  }

  .xl-lib-content-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 2560px) {
  .l-lib-header-container,
  .l-lib-footer-container,
  .l-lib-content-container {
    padding-left: 256px;
    padding-right: 256px;
  }

  .xl-lib-content-container {
    max-width: 2560px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 3840px) {
  .l-lib-header-container,
  .l-lib-footer-container,
  .l-lib-content-container {
    padding-left: 910px;
    padding-right: 910px;
  }

  .xl-lib-content-container {
    max-width: 2560px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
