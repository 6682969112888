//all Library items styles starts with 'lib-'

//we use same principe as  bootstrap for media
//No media query for mobile since this is the default in Bootstrap
//that mean we define  our styles  for mobile  devicres first , then by @media keyword  we  determine for all devices (desctop, tablet etc..)

//use  such media resolution:

// Small devices (landscape phones, 576px and up)

/* @media (min-width: 576px) {
    ...
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    ...
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    ...
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    ...
} */

button {
  white-space: nowrap;
}

.lib-btn {
  @include lib-txt-weight-600;
  @include lib-txt-4;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 22px;
  border: none;

  &.lib-btn-wide {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.lib-btn-no-adaptive {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & .lib-icon {
    height: 14px;
    width: 14px;
    color: var(--lib-color-dark-300);
  }

  &:hover:not(:disabled) {
    & .lib-icon {
      color: inherit;
    }
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled],
  &[disabled=disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.lib-btn-wrapper {
  @extend .lib-btn;
}

.lib-btn-primary {
  @extend .lib-btn;

  background-color: var(--lib-color-primary);
  color: #fff;
  text-decoration: none;  

  &:active:not(:disabled) {
    background: var(--lib-color-primary);
    outline: 0;
    text-decoration: underline;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    background-color: var(--lib-color-denim-400);
  }
}

.lib-btn-secondary {
  @extend .lib-btn;

  color: var(--lib-color-primary);
  background: #fff;
  border-color: var(--lib-color-primary);
  border-width: 1px;
  border-style: solid;
  text-decoration: none;

  &:active:not(:disabled) {
    background: #fff;
    color: var(--lib-color-primary);
    outline: 0;
    text-decoration: underline;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background: var(--lib-color-denim-200);
    color: var(--lib-color-primary);
    text-decoration: none;
  }
}

.lib-btn-denim {
  @extend .lib-btn;

  color: var(--lib-color-primary);
  background: #fff;
  border-color: var(--lib-color-button-border);
  border-width: 1px;
  border-style: solid;

  &:active:not(:disabled) {
    color: var(--lib-color-primary);
    background: #fff;
    outline: 0;
    text-decoration: underline;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    text-decoration: underline;
  }
}

.lib-btn-denim-secondary {
  @extend .lib-btn;

  color: #323232;
  background: #fff;
  border: 1px solid #c8cfdc;

  &:active:not(:disabled) {
    border: 1px solid rgba(var(--lib-color-primary-decimal), 0.6);
    color: var(--lib-color-primary);
    outline: 0;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    border: 1px solid rgba(var(--primary-hover-color-decimal), 0.6);
    color: var(--primary-hover-color);
  }
}

.lib-btn-danger {
  @extend .lib-btn;

  color: #fc0d1b;
  background: #fff1f2;
  border: 0;

  &:active:not(:disabled) {
    background: #fff1f2;
    color: #fc0d1b;
    outline: 0;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background: #ffd8d8;
    color: #fc0d1b;
  }
}

.lib-btn-text {
  @extend .lib-btn;

  color: var(--lib-color-primary);
  border: 0;
  background: 0;
  padding: 6px 0;

  &:active:not(:disabled) {
    color: var(--lib-color-primary);
    outline: 0;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: var(--primary-hover-color);
  }
}

.lib-btn-empty {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration-line: none;

  &:active:not(:disabled) {
    text-decoration-line: none;
    outline: 0;
  }

  &:focus:not(:disabled) {
    text-decoration-line: none;
    outline: 0;
  }

  &:hover:not(:disabled) {
    background-color: transparent;
    background-repeat: no-repeat;
    //text-decoration-line: none !important;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
}

.lib-btn-back-to-top {
  position: fixed;
  bottom: -10px;
  right: 5%;
  background: #fff;
  border: 0;
  outline: none;
  box-shadow: 0 0 10px rgba(217, 224, 227, 0.7);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  transform: translateY(101%);
  z-index: 99;

  & .lib-icon {
    transform: rotate(90deg);
    height: 14px;
    width: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:active:not(:disabled) {
    box-shadow: 0 0 10px rgba(217, 224, 227, 0.7);
    color: unset;
    border: 0;
    outline: 0;
  }

  &:focus:not(:disabled) {
    outline: 0;
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 10px rgba(252, 86, 31, 0.2);
    color: var(--primary-hover-color);
  }
}

@media (min-width: 576px) {
  //
}

@media (min-width: 768px) {

  %lib-btn {
    font-size: 18px;
    line-height: 21px;
    padding: 10px 20px;

    &.lib-btn-wide {
      padding-left: 24px;
      padding-right: 24px;
    }

    & .lib-icon {
      height: 18px;
      width: 18px;
    }
  }

  .lib-btn-text {
    line-height: 21px;
  }
}

@media (min-width: 992px) {
  .lib-btn-text {
    line-height: 21px;
  }

  .lib-btn-back-to-top {
    width: 56px;
    height: 56px;

    & .lib-icon {
      height: 18px;
      width: 18px;
    }
  }
}

@media (min-width: 1200px) {
  //
}
