.libitems-contact-page-wrapper {
    display: flex;
    //justify-content: center;
    width: auto;
    flex-direction: column;
    min-height: calc(100% - 468px);

    .libitems-contact-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        width: auto;


        .contact-page-content {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;

            &__title {
                width: 100%;
                height: 60px;
                //background-color: aqua;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 58px;
                letter-spacing: -0.05em;
                color: var(--lib-color-dark-700);

            }

            &__title-des {
                width: 100%;
                height: 40px;
                //background-color: rgb(195, 255, 0);
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: var(--lib-color-dark-700);
                margin-top: 10px;
            }

            &__name-input {
                width: 100%;
                height: 17px;
                //background-color: rgb(195, 255, 0);
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;                
                flex: none;
                align-self: stretch;
                flex-grow: 0;
                color: var(--lib-color-dark-700);
                margin-top: 10px;
                margin-bottom: 10px;
            }

            input {
                height: 40px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;                
                background: var(--lib-color-surface-1);
                border-radius: 0;
                outline: none;
                box-shadow: none;
                border: 1px solid var(--lib-color-dark-100);
                padding: 0.5rem;
                padding-bottom: 9px;  // prevents jumping of the input field

                &:focus {
                    color: var(--lib-color-dark-700);
                  
                    border-bottom: 2px solid var(--lib-color-denim-500);
                    outline: none;        
                    border-radius: 0;
                    padding-bottom: 8px; // prevents jumping of the input field                
                    box-shadow: none; 
                }


            }

            textarea {
                height: 174px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;                
                background: var(--lib-color-surface-1);
                border-radius: 0;
                
                border: 1px solid var(--lib-color-dark-100);
                outline: none;
                box-shadow: none;

                padding: 0.5rem;
                padding-bottom: 9px;  // prevents jumping of the input field

                &:focus {
                    color: var(--lib-color-dark-700);
                  
                    border-bottom: 2px solid var(--lib-color-denim-500);
                    outline: none;        
                    border-radius: 0;
                    padding-bottom: 8px; // prevents jumping of the input field                
                    box-shadow: none; 
                }


            }
            .aemail {
                color: var(--lib-color-denim-500);
            }

            &__bth {
                width: 160px;
                height: 38px;
                
                margin-top: 20px;
            }

            &__email-support {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .contact-page-send-mail-status-content {
            display: none;
            flex-direction: column;
            width: 400px;            

            &__title-icon {
                width: 100%;
                height: 144px;
                img {
                    width: 144px;
                    height: 144px;
                }
            }   
            &__title {
                width: 100%;
                height: fit-content;
                //background-color: aqua;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 58px;
                letter-spacing: -0.05em;
                color: var(--lib-color-dark-700);

            }
            
            &__title-des {
                width: 100%;
                height: 40px;
                //background-color: rgb(195, 255, 0);
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: var(--lib-color-dark-700);
                margin-top: 10px;
            }
            .aemail {
                color: var(--lib-color-denim-500);
            }
            &__bth {
                width: 160px;
                height: 38px;
                
                margin-top: 20px;
            }
            &__email-support {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .password-input {
            position: relative;
            width: 100%;
        }
        .password-icons-block {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            display: flex;
            align-items: center;
            padding-right: 12px;
            line-height: 20px;
            font-size: 14px;            
        }
        .password-icons {
            display: block;
            height: 18px;
            color: var(--lib-color-dark-700);
            pointer-events: auto;
        }
        .hidden {
            display:none;
        }
    }
}

@media (min-width: 768px) {
    .libitems-contact-page-wrapper {
        .libitems-contact-page-content {
            .contact-page-content {
                width: 400px;
                padding: 0;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .libitems-contact-page-wrapper {
        .libitems-contact-page-content {}
    }
}

@media screen and (min-width: 1900px) {
    .libitems-contact-page-wrapper {
        .libitems-contact-page-content {}
    }
}

@media screen and (min-width: 2560px) {
    .libitems-contact-page-wrapper {
        .libitems-contact-page-content {}
    }
}

@media screen and (min-width: 3200px) {
    .libitems-contact-page-wrapper {
        .libitems-contact-page-content {}
    }
}