@import url(https://fonts.googleapis.com/css?family=Inter);

@mixin font-style {
  font-style: normal;
  font-variant-ligatures: none;
}

@mixin font-family {
  font-family: $inter-font-family;
}

@mixin lib-txt-0 {
  margin: 0;
  padding: 0;
  font-size: 4rem; /* 64px */
  line-height: 4.425rem; /* 71px */
  letter-spacing: -0.05em;
}

@mixin lib-txt-1 {
  font-size: 2.625rem; /* 42px */
  line-height: 4rem; /* 64px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-2 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.75rem; /* 44px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-3 {
  font-size: 1.5rem; /* 24px */
  line-height: 1.85rem; /* 29px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-4 {
  font-size: 1.125rem; /* 18px */
  line-height: 1rem; /* 16px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-5 {
  font-size: 1rem; /* 16px */
  line-height: 1.2rem; /* 19px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-6 {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-7 {
  font-size: 0.75rem; /* 12px */
  line-height: 1.125rem; /* 18px */
  margin: 0;
  padding: 0;
}

@mixin lib-txt-weight-600 {
  font-family: $inter-font-family;
  font-weight: 600;
}

@mixin lib-txt-weight-500 {
  font-family: $inter-font-family;
  font-weight: 500;
}

@mixin lib-txt-weight-400 {
  font-family: $inter-font-family;
  font-weight: 400;
}

@mixin lib-txt-weight-300 {
  font-family: $inter-font-family;
  font-weight: 300;
}

@mixin lib-txt-italic {
  font-family: $inter-font-family;
  font-style: italic;
}

.lib-txt-0 {
  @include lib-txt-0;
}

.lib-txt-1 {
  @include lib-txt-1;
}

.lib-txt-2 {
  @include lib-txt-2;
}

.lib-txt-3 {
  @include lib-txt-3;
}

.lib-txt-4 {
  @include lib-txt-4;
}

.lib-txt-5 {
  @include lib-txt-5;
}

.lib-txt-6 {
  @include lib-txt-6;
}

.lib-txt-7 {
  @include lib-txt-7;
}

.lib-txt-weight-600 {
  @include lib-txt-weight-600;
}

.lib-txt-weight-500 {
  @include lib-txt-weight-500;
}

.lib-txt-weight-300 {
  @include lib-txt-weight-300;
}

.lib-txt-italic {
  @include lib-txt-italic;
}

.lib-text-underline {
  text-decoration: underline;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .lib-txt-sm-1 {
    @include lib-txt-1;
  }

  .lib-txt-sm-2 {
    @include lib-txt-2;
  }

  .lib-txt-sm-3 {
    @include lib-txt-3;
  }

  .lib-txt-sm-4 {
    @include lib-txt-4;
  }

  .lib-txt-sm-5 {
    @include lib-txt-5;
  }

  .lib-txt-sm-6 {
    @include lib-txt-6;
  }

  .lib-txt-sm-7 {
    @include lib-txt-7;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .lib-txt-md-1 {
    @include lib-txt-1;
  }

  .lib-txt-md-2 {
    @include lib-txt-2;
  }

  .lib-txt-md-3 {
    @include lib-txt-3;
  }

  .lib-txt-md-4 {
    @include lib-txt-4;
  }

  .lib-txt-md-5 {
    @include lib-txt-5;
  }

  .lib-txt-md-6 {
    @include lib-txt-6;
  }

  .lib-txt-md-7 {
    @include lib-txt-7;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .lib-txt-lg-1 {
    @include lib-txt-1;
  }

  .lib-txt-lg-2 {
    @include lib-txt-2;
  }

  .lib-txt-lg-3 {
    @include lib-txt-3;
  }

  .lib-txt-lg-4 {
    @include lib-txt-4;
  }

  .lib-txt-lg-5 {
    @include lib-txt-5;
  }

  .lib-txt-lg-6 {
    @include lib-txt-6;
  }

  .lib-txt-lg-7 {
    @include lib-txt-7;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .lib-txt-xl-1 {
    @include lib-txt-1;
  }

  .lib-txt-xl-2 {
    @include lib-txt-2;
  }

  .lib-txt-xl-3 {
    @include lib-txt-3;
  }

  .lib-txt-xl-4 {
    @include lib-txt-4;
  }

  .lib-txt-xl-5 {
    @include lib-txt-5;
  }

  .lib-txt-xl-6 {
    @include lib-txt-6;
  }

  .lib-txt-xl-7 {
    @include lib-txt-7;
  }
}
