.discover-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
  background: var(--lib-color-surface);
  height: fit-content;
  min-height: 100%;
  margin-top: 2.2rem;
}

.discover-categories-menu {
  display: none;
  transition: opacity 200ms, display 200ms;
  opacity: 0;
  
  &.collapse.show {
    width: $categories-menu-width;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    transition: opacity 200ms, display 200ms;
    opacity: 1;
  }
}

.discover-categories-menu-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0;

  
}
.menu-mobile-container {
  height: 2rem;
  position: fixed;
  z-index: 4;
  top: 4.2rem;
  width: 100%;
  
  &.open[aria-expanded=true]{
    display: none;
    transition: opacity 200ms, display 200ms;  
    opacity: 0;
  }

   //&.close.collapsed
  &.close[aria-expanded=false]
  {
    display: none;
    transition: opacity 200ms, display 200ms;
    opacity: 0;
  }
  
  &.close[aria-expanded=true]
  {
    display: flex;
    opacity: 1;
  }
}

.quick-search {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: var(--lib-color-surface-1);
  padding-top: 17px;
  padding-left: 17px;
  padding-right: 0;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--lib-color-gray-87);
  position: relative;
  
  input {
      @include lib-txt-6;
      @include lib-txt-weight-600;  
     
      background-color: var(--lib-color-surface-1);
      margin-left: 5px;
      height: 40px;
      border-radius: 0;
      border:0;
      border-bottom: 2px solid var(--lib-color-surface-1);
      padding-right: 20px;
      max-width: 220px;
  
      &:focus {
        color: var(--lib-color-dark-700);
        background-color: #fff;        
        border-bottom: 2px solid var(--lib-color-denim-500);
        outline: none;        
        border-radius: 0;
        height: 40px;
      }
    }
    button {
      position: absolute;
      right: 8px;
      border:0;
      background-color: transparent;
      display: flex;
      padding: 0;
      margin: 0;
      
      &.visible {
        visibility: visible;
      }

      &.hidden {
        visibility: hidden;
      }

      &:hover{
        cursor: pointer;
      }
    }    
}

.list-categories {
  width: 100%;
  border: 0;
  border-radius: 0;
  margin-top: 16px;
  border-bottom: 1px solid var(--lib-color-gray-87);

}

.category-menu-item {
  @include lib-txt-weight-600;
  @include lib-txt-6;

  height: 2.5rem;
  border: 0;
  border-radius: 0;
  color: var(--lib-color-dark-600);
  padding-left: 0;
  padding-top: 0.5rem;

  &:hover {
    color: var(--lib-color-primary);

    & * {
      color: var(--lib-color-primary);
    }
  }

  &.active {
    color: var(--lib-color-button-border);
    background-color: var(--lib-color-denim-200);
    border-right: 2px solid var(--lib-color-button-border);
    margin: 0 !important;

    & * {
      color: var(--lib-color-button-border);
    }
  }

  & svg {
    margin: 0 16px;
  }

  &__label {
    //...
  }

  &__count {
    @include lib-txt-weight-400;
    margin-left: 8px !important;
    color: var(--lib-color-dark-300);
  }
}

.discover-main-view {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  overflow: hidden;
  opacity: 1;
  transition: opacity 200ms, display 200ms;

  &.collapse{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    overflow: hidden;
    opacity: 1;
    transition: opacity 200ms, display 200ms;
  }

  &.collapse.show {
    display: none;
    transition: opacity 200ms, display 200ms;  
    opacity: 0;
  }
}

.lib-spinner,
.loading-container,
.no-results-container {  
  margin: 8px;  

  &.not-visible {
    display: none;
    margin: 0;
    padding: 0;
  }
}

.lib-discover-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  margin: 0;
  padding: 0;
  height: auto;

  &.not-visible {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
  }

  &.addons-item {
    grid-template-columns: repeat(1, 1fr);
  }

  &.materials-item {
    grid-template-columns: repeat(2, 1fr);
  }

  &.models-item {
    grid-template-columns: repeat(2, 1fr);
  }

  &.textures-item {
    grid-template-columns: repeat(2, 1fr);
  }

  &.blogs-item {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 1rem;
    width: 100%;
  }
}

.lib-preview-item-thumbnail {
  position: relative;
  padding: 0;
  margin: 0;
  background: #fff;
  max-width: 176px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &.addons-item,
  &.blogs-item {
    max-width: 600px;
  }

  .lib-item-preview-link {
    overflow: hidden;

    &>img {
      max-height: 176px;
      width: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    &:hover > .bottom-label-item {    
      color: #FFFFFF; // rgba(255, 255, 255, .4);
      position: absolute;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      vertical-align: middle;
      left: 0;
      bottom: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      //color: #0C89FD;
      background: rgba(0, 0, 0, 0.5);
      z-index: 3;
      width:100%;
      height: 3.75em;
      -webkit-transform: translate3d(0,0,0);
	    transform: translate3d(0,0,0);
      hyphens: auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
    &:hover > .bundle-container-item > .corner {      
      opacity: 0;
    }
    &:hover > .bundle-container-item > .trapezoid {
      opacity: 1;
      transition: opacity 0.35s ease-in-out;
    }
  }

  .price-item {
    color: rgba(255, 255, 255, .4);
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: auto;
    right: 0;
    top: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0C89FD;
    background: linear-gradient(180deg, #FAFAFA 0%, #E4E4E4 100%);
    z-index: 3;
  }

  .bundle-container-item {    
    position: absolute;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;    
    
    z-index: 3;   
  }

  .corner {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    width: 0;
    height: 0;
    border-top: 36px solid var(--lib-color-primary);
    border-right: 36px solid transparent;
    & span {
      position: absolute;
      top: 0;
      left: 6px;      
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;          
      z-index: 4;
    }
  }

  .trapezoid {
    opacity: 0;
    width: 100px;
    height: 0;      
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 24px solid var(--lib-color-primary);
    transform: rotate(-45deg);
    position: absolute;
    left: -23px;
    top:15px;

    & span {
      position: absolute;
      top: 0;
      left: 6px;      
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;          
      z-index: 4;
    }   
  }    

  .bottom-label-item {    
      color: #FFFFFF; // rgba(255, 255, 255, .4);
      position: absolute;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;  
      vertical-align: middle; 
      left: 0;
      bottom: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;      
      background: rgba(0, 0, 0, 0.5);
      z-index: 3;
      width:100%;
      height: 3.75em;
      -webkit-transition: -webkit-transform 0.35s;
	    transition: transform 0.35s;
	    -webkit-transform: translate3d(0,100%,0);
	    transform: translate3d(0,100%,0);
  }

  .price-item-detaile {
    background-color: rgba(0, 0, 0, .3);
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
  }
}


@media screen and (min-width: 768px) {
  .discover-content {
    margin-top: 0;
  }
  .lib-discover-grid-container {
    grid-template-columns: repeat(4, 1fr);
    width: auto;

    &.addons-item {
      grid-template-columns: repeat(1, 1fr);
    }

    &.materials-item {
      grid-template-columns: repeat(4, 1fr);
    }

    &.models-item {
      grid-template-columns: repeat(4, 1fr);
    }

    &.textures-item {
      grid-template-columns: repeat(4, 1fr);
    }
    
    &.blogs-item {
      grid-template-columns: repeat(1, 1fr);
      width: auto;
    }
  }

  .lib-preview-item-thumbnail {
    max-width: 176px;

    &.addons-item,
    &.blogs-item {
      max-width: 352px;
    }

    .lib-item-preview-link {
      &>img {
        max-height: 176px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }
  }
  .discover-main-view {
    min-width: 52em;
    border-left: 1px solid var(--lib-color-gray-87);
  }

  .discover-categories-menu {
    width: $categories-menu-width;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start; 
    opacity: 1;   
  }

  .discover-categories-menu-mobile-container {
    display: none;
    z-index: -1;    
  }
}

@media screen and (min-width: 1366px) {
  .lib-discover-grid-container {
    grid-template-columns: repeat(6, 1fr);
    width: auto;

    &.addons-item {
      grid-template-columns: repeat(3, 1fr);
    }

    &.materials-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.models-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.textures-item {
      grid-template-columns: repeat(6, 1fr);
    }
    
    &.blogs-item {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .lib-preview-item-thumbnail {
    max-width: 202px;

    &.addons-item,
    &.blogs-item {
      max-width: 404px;
    }

    .lib-item-preview-link {
      &>img {
        max-height: 202px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
    }
  }
  .discover-main-view {
    min-width: 62em;
    border-left: 1px solid var(--lib-color-gray-87);
  }
}

@media screen and (min-width: 1900px) {
  .lib-discover-grid-container {
    grid-template-columns: repeat(6, 1fr);
    width: auto;

    &.addons-item {
      grid-template-columns: repeat(3, 1fr);
    }

    &.materials-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.models-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.textures-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.blogs-item {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .lib-preview-item-thumbnail {
    max-width: 276px;

    &.addons-item,
    &.blogs-item {
      max-width: 552px;
    }

    .lib-item-preview-link {
      &>img {
        max-height: 276px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        padding-top: 0.2em;
    }
    }
  }
  .discover-main-view {
    min-width: 92em;
    border-left: 1px solid var(--lib-color-gray-87);
  }
}

@media screen and (min-width: 2560px) {
  .lib-discover-grid-container {
    grid-template-columns: repeat(6, 1fr);
    width: auto;

    &.addons-item {
      grid-template-columns: repeat(3, 1fr);
    }

    &.materials-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.models-item {
      grid-template-columns: repeat(6, 1fr);
    }

    &.textures-item {
      grid-template-columns: repeat(6, 1fr);
    }
    
    &.blogs-item {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .lib-preview-item-thumbnail {
    max-width: 350px;

    &.addons-item,
    &.blogs-item {
      max-width: 700px;
    }

    .lib-item-preview-link {
      &>img {
        max-height: 350px;
      }
      &:hover > .bottom-label-item {    
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    }
  }

  .discover-main-view {
    min-width: 132em;
    border-left: 1px solid var(--lib-color-gray-87);
  }
}
