//all libitems styles starts with 'lib-'

//we use same principe as  bootstrap for media
//No media query for mobile since this is the default in Bootstrap
//that mean we define  our styles  for mobile  devicres first , then by @media keyword  we  determine for all devices (desctop, tablet etc..)

//use  such media resolution:

// Small devices (landscape phones, 576px and up)

/* @media (min-width: 576px) {
    ...
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    ...
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    ...
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    ...
} */

.footer-container {
  @include font-family;

  background: var(--lib-color-dark-800);
  display: flex;
  padding-top: 80px;
  padding-bottom: 44px;
  color: var(--lib-color-surface);
  opacity: 1;
  transition: opacity 200ms, display 200ms;

  &.collapse.show {    
    display: none;
    transition: opacity 200ms, display 200ms;  
    opacity: 0;
  }
  &.collapse {    
    display: flex; 
    opacity: 1;
    transition: opacity 200ms, display 200ms;
  }

  & .row {
    margin: 0;
  }

  & *[class*='col'] {
    padding: 0;
  }

  & a {
    color: var(--lib-color-dark-200) !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.general {
  .logo {
    max-width: 230px;
    max-height: 130px;
    padding-bottom: 100px;
  }

  .follow-us-wrapper {
    & a {
      text-decoration: none !important;
      color: var(--lib-color-surface) !important;
      padding-right: 22px;
    }

    & svg {
      text-decoration: none !important;

      &:hover {
        transform: scale(1.1) !important;
      }
    }

    & img {
      height: 24px;
    }

    & img:hover {
      opacity: 0.7;
    }
  }
}

.sub-footer {
  @include font-family;

  background: var(--lib-color-dark-800);
  padding-top: 0;
  padding-bottom: 42px;
  color: var(--lib-color-dark-200);
  opacity: 1;
  transition: opacity 200ms, display 200ms;

  &.collapse.show {    
    display: none;
    transition: opacity 200ms, display 200ms;  
    opacity: 0;
  }
  &.collapse {    
    display: flex; 
    opacity: 1;
    transition: opacity 200ms, display 200ms;
  }

  & .row {
    margin: 0;
  }

  & *[class*='col'] {
    padding: 0;
  }

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: 576px) {
  //
}

@media (min-width: 768px) {
  //
}

@media (min-width: 992px) {
  //
}

@media (min-width: 1200px) {
  //
}
