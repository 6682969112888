@import "../utils/variables";

html {
  font-size: 100%;
  height: 100%;
}

body {
  height: 100%;
  font-size: 100%;
  background-color: $main-backgroundColor;
  scrollbar-gutter: stable both-edges;
  overflow-y: scroll;
  //to allow animation work correct
  //and belong to custom select
  //overflow-x:hidden;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
}

a,
button,
input,
div,
span {
  font-variant-ligatures: none;
}

a {
  color: #242424;
  text-decoration: none;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #242424;
  text-decoration: none;
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}
