.comments-tree-level {
    display: flex;
}

.comments-tree-items-list {
    padding-left: 0;
    list-style: none;
    width: 96%;
    margin-left: auto;

}

.comments-ul{
    width: 100%;
    list-style: none;
}

.comments-li{
    width: 100%;
    list-style: none;
}

.libitems-comments {
    width: 100%;
    border: 1px solid var(--lib-color-dark-100);
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
        width: 100%;
        height: 50px;
        background-color: #ECF1F7;
        display: flex;
        flex-direction: row;

        &__nikname {
            width: auto;
            height: 50px;
            order: 0;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            /* identical to box height */

            text-align: right;

            /* On surface/600 */

            color: #31425A;
        }

        &__label-owner {
            width: auto;
            height: auto;
            order: 1;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 12px;
            margin-left: auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            /* identical to box height */


            /* On surface/600 */

            color: #31425A;
        }

        &__type-comment {
            width: auto;
            height: 50px;            
            order: 2;
            padding-left: 10px;
            padding-right: 10px;            
            display: flex;

            &__icon {
                width: 90px;
                height: 30px;
                display: flex;
                align-content: center;

                &.bug {
                    width: 90px;
                    height: 30px;
                    background-color: #c21919;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.question {
                    width: 90px;
                    height: 30px;
                    background-color: #1eb700;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.proposal {
                    width: 90px;
                    height: 30px;
                    background-color: #e6e321;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.review {
                    width: 90px;
                    height: 30px;
                    background-color: #0e8bd9;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.resolution {
                    width: 90px;
                    height: 30px;
                    background-color: #0e8bd9;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &__date-public {
            width: auto;
            height: auto;

            order: 3;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */


            /* On surface/500 */

            color: #667286;
        }

        &__reply {
            display: none; // TODO: temporaly hidden 
            width: 50px;
            height: 50px;            
            order: 4;

            img {
                width: 40px;
            }
        }

    }

    &__body {
        width: 100%;
        height: auto;        
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */


        /* On surface/700 */

        color: #162842;
        padding: 10px;
    }

    &__footer {
        width: 100%;        
        background-color: #F9F9F9;
        border-top: 1px solid var(--lib-color-dark-100);
        margin: auto;
        display: flex;        
        flex-direction: row;

        &__like-status {
            width: auto;
            min-height: 40px;            
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &__status-container {
                width: auto;
                height: 40px;
                display: flex;
                margin-right: 10px;
                flex-direction: row;

                &__icon {
                    width: 40px;
                    height: 40px;

                    img {
                        padding-top: 5px;
                        width: 30px;
                    }

                    order: 0;
                }

                &__number {
                    width: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    order: 1;
                    color: var(--lib-color-dark-500);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &__owner-mark {
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: auto;
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: var(--lib-color-dark-500);
        }
    }
}