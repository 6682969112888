.article-wrapper {
    display: flex;
    //justify-content: center;
    flex-direction: column;
    min-height: calc(100% - 468px);


    .article-body-wrapper {
        width: 100%;
        height: auto;
        min-height: 174px;

        background: var(--lib-color-dark-50);
        order: 0;
        display: flex;
        justify-content: center;
        flex-direction: row;

        &.change-log {
            min-height: 234px;
        }

        .article-body-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            justify-content: center;
            //background-color: rgb(255, 0, 217);
            order: 1;
            // width: 850px;

            &__user {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 50px;

                &__user-preview {
                    width: 50px;
                    height: 50px;
                    //background-color: #667286;
                    order: 0;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                }

                &__user-name {
                    width: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: var(--lib-color-dark-500);

                    order: 1;
                    padding-left: 10px;
                    margin-top: 15px;

                    &>a{
                        color: var(--lib-color-dark-500);
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &__date-last-update {
                    width: auto;
                    height: 30px;
                    //background-color: rgb(132, 255, 0);
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: var(--lib-color-dark-500);
                    margin-top: 15px;
                    order: 2;
                    padding-left: 10px;
                }
            }



            &__article-name {
                width: 100%;
                min-height: 50px;
                height: auto;                
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                padding-top: 10px;
                letter-spacing: -0.05em;
                color: var(--lib-color-dark-700);
                display: flex;
                flex-direction: column;

                &>a{
                    color: var(--lib-color-dark-700);
                    &:hover {                        
                        color: var(--lib-color-primary);
                        & * {
                            color: var(--lib-color-primary);                            
                          }
                    }
                }
                .back-label {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 4px;
                    color: var(--lib-color-dark-700);
                }

            }
        }
    }

    .article-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        order: 1;
        margin-left: auto;
        margin-right: auto;


        .article-preview-wrapper {
            display: flex;
            width: 100%;
            padding-top: 64px;
            order: 0;

            .preview-img {
                width: 100%;
                object-fit: cover;
            }

            .viewer-content-main {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .article-info-wrapper {
            width: 100%;
            padding: 0;
            justify-content: center;
            order: 1;

            .article-description-wrapper {
                // background-color: brown;
            }
        }


        .article-blog-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            //justify-content: center;
            order: 1;
            width: auto;

            .article-description-wrapper {
                // background-color: brown;
            }


            .article-blog-item-text-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;
                flex: none;
                order: 1;
                flex-grow: 0;

                .article-blog-item-title {
                    display: none;
                    width: 100%;
                    height: 100px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: -0.05em;
                    padding-left: 20px;
                    padding-bottom: 50px;
                    padding-top: 20px;
                    color: var(--lib-color-dark-700);
                    order: 0;
                }

                .article-blog-item-text {
                    padding-top: 20px;
                    max-width: 850px;
                    height: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--lib-color-dark-700);
                    order: 1;
                    justify-content: center;
                }
            }
        }

        .article-changelog-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            flex: none;
            order: 1;
            flex-grow: 0;

            .article-changelog-text-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;
                //background-color: #00B94A;
                flex: none;
                order: 1;
                flex-grow: 0;

                &__title {
                    width: 100%;
                    height: 100px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: -0.05em;
                    padding-left: 20px;
                    padding-bottom: 50px;
                    padding-top: 20px;
                    color: var(--lib-color-dark-700);
                    order: 0;
                }

                &__text {
                    max-width: 850px;
                    height: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 20px;
                    color: var(--lib-color-dark-700);
                    order: 1;
                }
            }
        }

        .article-manual-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0;
            justify-content: center;
            //background-color: rgb(255, 0, 217);
            order: 1;
            width: 100%;

            .article-description-wrapper {
                // background-color: brown;

            }


            .article-manual-content-left {
                width: 400px;
                height: 100%;
                display: flex;
                flex-direction: column;
                order: 0;
                flex-grow: 0;
                border-right: 1px solid var(--lib-color-gray-87);


                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    position: relative;
                }


                li {
                    list-style-type: none;
                    border-left: 20px solid #000;
                    margin-left: 1em;
                }

                li div {
                    padding-left: 1em;
                    position: relative;
                }

                li div::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -20px;
                    bottom: 50%;
                    width: 0.75em;
                    border: 2px solid #000;
                    border-top: 0 none transparent;
                    border-right: 0 none transparent;
                }

                ul>li:last-child {
                    border-left: 20px solid transparent;
                }




            }

            .article-manual-item-text-container {
                display: flex;
                flex-direction: column;
                width: calc(100% - 400px);
                height: auto;
                //background-color: #00B94A;
                flex: none;
                order: 1;
                flex-grow: 0;

                .article-manual-item-title {
                    width: 100%;
                    height: 100px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: -0.05em;
                    padding-left: 20px;
                    padding-bottom: 50px;
                    padding-top: 20px;
                    color: var(--lib-color-dark-700);
                    order: 0;
                }

                .article-manual-item-text {
                    max-width: 850px;
                    height: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 20px;
                    color: var(--lib-color-dark-700);
                    order: 1;
                }
            }
        }

        .article-tag-wrapper {
            order: 2;
            //background-color: rgb(175, 160, 29);
            width: 100%;
            height: 90px;
            border-top: 1px solid var(--lib-color-gray-87);

            .article-tag-content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;

                &__item {
                    width: auto;
                    height: 32px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 32px;
                    flex: none;
                    flex-grow: 0;
                    margin: 10px 10px;
                    padding: 0px 16px;
                    background-color: var(--lib-color-denim-200);
                    color: var(--lib-color-dark-600);

                    &:hover {
                        color: var(--lib-color-primary);                       
                    }
                }
            }
        }

        .article-comments-type-content{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
           // background-color: brown;
           border-top: 1px solid var(--lib-color-gray-87);
            order: 2;

            &__comment-type {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 32px;
                width: auto;
                height: 32px;
                margin: 5px 5px;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0px 16px;
                background-color: var(--lib-color-denim-200);
                color: var(--lib-color-dark-600);
            }


        }

        .article-comments-wrapper {
            width: 100%;
            height: auto;
            //background-color: rgb(255, 4, 255);
            order: 3;
        }
    }
}

.lib-article {
    max-width: 850px;
    margin: 0 8px 0 8px;
}

.lib-article * {
    // @include lib-txt-6;
    // @include lib-txt-weight-400;
    color: var(--lib-color-dark-600);
}

.lib-article :is(h1, h2, h3, h4, h5, h6) {
    @include lib-txt-4;
    @include lib-txt-weight-500;
    margin-top: 24px;
    color: var(--lib-color-dark-700);
}

.lib-article p {
    @include lib-txt-6;
    @include lib-txt-weight-400;
    margin: 8px 0;
    line-height: 128%;
}

@media (min-width: 768px) {
    .lib-article {
        margin: 0 0 16px 0;
    }

    .lib-article p {
        @include lib-txt-4;
        @include lib-txt-weight-400;
        margin: 8px 0;
        line-height: 128%;
    }

    .lib-article :is(h1, h2, h3, h4, h5, h6) {
        @include lib-txt-3;
        @include lib-txt-weight-500;
        margin-top: 24px;
        color: var(--lib-color-dark-700);
    }
}


@media screen and (min-width: 768px) {
    .article-description-wrapper {
        .discover-grid-container {
            grid-template-columns: repeat(2, 1fr);
            width: auto;
        }
    }

    .article-wrapper {
        .article-content {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .article-comments-type-content{
                flex-direction: row;
            }
        }

        .article-base-width {
            width: auto;
        }

        .article-manual-width {
            width: auto;
        }

        .article-content-left {}

        .article-content-main {}


    }
}

@media screen and (min-width: 1366px) {
    .article-description-wrapper {
        .discover-grid-container {
            grid-template-columns: repeat(3, 1fr);
            width: auto;
        }

    }

    .article-manual-item-text-container {
        width: 100%;
    }

    .article-wrapper {
        .article-content {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .article-base-width {
            width: 850px;
        }

        .article-manual-width {
            width: 1300px;
        }

        .article-content-left {
            height: 100%;
            padding-left: 5px;
            padding-top: 5px;
            order: 0;
        }

        .article-content-main {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            order: 1;
        }


    }
}

@media screen and (min-width: 1900px) {
    .article-description-wrapper {

        .discover-grid-container {
            grid-template-columns: repeat(3, 1fr);
            width: auto;
        }

    }

    .article-manual-item-text-container {
        width: calc(100% - 400px);
    }

    .article-wrapper {
        .article-content {
            width: 850px;
        }

        .article-base-width {
            width: 850px;
        }

        .article-manual-width {
            width: 1300px;
        }

        .article-content-left {
            width: 450px;
            height: 100%;
            order: 0;
        }

        .article-content-main {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            order: 1;
        }

    }
}

@media screen and (min-width: 2560px) {
    .article-description-wrapper {
        .discover-grid-container {
            grid-template-columns: repeat(4, 1fr);
            width: auto;
        }

    }

    .article-manual-item-text-container {
        width: calc(100% - 400px);
    }

    .article-wrapper {
        .article-content {
            width: 850;
        }

        .article-base-width {
            width: 850px;
        }

        .article-manual-width {
            width: 1300px;
        }


        .article-content-left {
            width: 450px;
            height: 100%;
            order: 0;
        }

        .article-content-main {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            order: 1;
        }


    }
}