.lib-icon,
.lib-icon-colored {
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  //vertical-align: middle;
  height: 14px;
  width: 14px;

  &.lib-icon-grey {
    color: var(--lib-color-icon);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: inherit;
    opacity: inherit;
    fill: currentColor;
    pointer-events: none;
  }
}

.lib-icon:not(.lib-icon-colored) {
  path {
    fill: currentColor;
    width: inherit;
    height: inherit;
    stroke: inherit;
  }
  &.lib-icon-no-fill {
    path {
      fill: none;
      width: inherit;
      height: inherit;
      stroke: currentColor;
    }
  }
}

@mixin icon-size($size-value) {
  height: #{$size-value}px;
  width: #{$size-value}px;
  min-width: #{$size-value}px;
}

@mixin lib-icon-size-64 {
  @include icon-size(64);
}

@mixin lib-icon-size-48 {
  @include icon-size(48);
}

@mixin lib-icon-size-36 {
  @include icon-size(36);
}

@mixin lib-icon-size-32 {
  @include icon-size(32);
}

@mixin lib-icon-size-28 {
  @include icon-size(28);
}

@mixin lib-icon-size-24 {
  @include icon-size(24);
}

@mixin lib-icon-size-22 {
  @include icon-size(22);
}

@mixin lib-icon-size-20 {
  @include icon-size(20);
}

@mixin lib-icon-size-18 {
  @include icon-size(18);
}

@mixin lib-icon-size-14 {
  @include icon-size(14);
}

.lib-icon.lib-icon-size-64 {
  @include lib-icon-size-64;
}

.lib-icon.lib-icon-size-48 {
  @include lib-icon-size-48;
}

.lib-icon.lib-icon-size-36 {
  @include lib-icon-size-36;
}

.lib-icon.lib-icon-size-32 {
  @include lib-icon-size-32;
}

.lib-icon.lib-icon-size-28 {
  @include lib-icon-size-28;
}

.lib-icon.lib-icon-size-24 {
  @include lib-icon-size-24;
}

.lib-icon.lib-icon-size-22 {
  @include lib-icon-size-22;
}

.lib-icon.lib-icon-size-20 {
  @include lib-icon-size-20;
}

.lib-icon.lib-icon-size-18 {
  @include lib-icon-size-18;
}

.lib-icon.lib-icon-size-14 {
  @include lib-icon-size-14;
}

@media (min-width: 576px) {
  .lib-icon.lib-icon-size-sm-64 {
    @include lib-icon-size-64;
  }

  .lib-icon.lib-icon-size-sm-48 {
    @include lib-icon-size-48;
  }

  .lib-icon.lib-icon-size-sm-36 {
    @include lib-icon-size-36;
  }

  .lib-icon.lib-icon-size-sm-32 {
    @include lib-icon-size-32;
  }

  .lib-icon.lib-icon-size-sm-28 {
    @include lib-icon-size-28;
  }

  .lib-icon.lib-icon-size-sm-24 {
    @include lib-icon-size-24;
  }

  .lib-icon.lib-icon-size-sm-22 {
    @include lib-icon-size-22;
  }

  .lib-icon.lib-icon-size-sm-20 {
    @include lib-icon-size-20;
  }

  .lib-icon.lib-icon-size-sm-18 {
    @include lib-icon-size-18;
  }

  .lib-icon.lib-icon-size-sm-14 {
    @include lib-icon-size-14;
  }
}

@media (min-width: 768px) {
  .lib-icon.lib-icon-size-md-64 {
    @include lib-icon-size-64;
  }

  .lib-icon.lib-icon-size-md-48 {
    @include lib-icon-size-48;
  }

  .lib-icon.lib-icon-size-md-36 {
    @include lib-icon-size-36;
  }

  .lib-icon.lib-icon-size-md-32 {
    @include lib-icon-size-32;
  }

  .lib-icon.lib-icon-size-md-28 {
    @include lib-icon-size-28;
  }

  .lib-icon.lib-icon-size-md-24 {
    @include lib-icon-size-24;
  }

  .lib-icon.lib-icon-size-md-22 {
    @include lib-icon-size-22;
  }

  .lib-icon.lib-icon-size-md-20 {
    @include lib-icon-size-20;
  }

  .lib-icon.lib-icon-size-md-18 {
    @include lib-icon-size-18;
  }

  .lib-icon.lib-icon-size-md-14 {
    @include lib-icon-size-14;
  }
}

@media (min-width: 992px) {
  .lib-icon.lib-icon-size-lg-64 {
    @include lib-icon-size-64;
  }

  .lib-icon.lib-icon-size-lg-48 {
    @include lib-icon-size-48;
  }

  .lib-icon.lib-icon-size-lg-36 {
    @include lib-icon-size-36;
  }

  .lib-icon.lib-icon-size-lg-32 {
    @include lib-icon-size-32;
  }

  .lib-icon.lib-icon-size-lg-28 {
    @include lib-icon-size-28;
  }

  .lib-icon.lib-icon-size-lg-24 {
    @include lib-icon-size-24;
  }

  .lib-icon.lib-icon-size-lg-22 {
    @include lib-icon-size-22;
  }

  .lib-icon.lib-icon-size-lg-20 {
    @include lib-icon-size-20;
  }

  .lib-icon.lib-icon-size-lg-18 {
    @include lib-icon-size-18;
  }

  .lib-icon.lib-icon-size-lg-14 {
    @include lib-icon-size-14;
  }
}

@media (min-width: 1200px) {
  .lib-icon.lib-icon-size-xlg-64 {
    @include lib-icon-size-64;
  }

  .lib-icon.lib-icon-size-xlg-48 {
    @include lib-icon-size-48;
  }

  .lib-icon.lib-icon-size-xlg-36 {
    @include lib-icon-size-36;
  }

  .lib-icon.lib-icon-size-xlg-32 {
    @include lib-icon-size-32;
  }

  .lib-icon.lib-icon-size-xlg-28 {
    @include lib-icon-size-28;
  }

  .lib-icon.lib-icon-size-xlg-24 {
    @include lib-icon-size-24;
  }

  .lib-icon.lib-icon-size-xlg-22 {
    @include lib-icon-size-22;
  }

  .lib-icon.lib-icon-size-xlg-20 {
    @include lib-icon-size-20;
  }

  .lib-icon.lib-icon-size-xlg-18 {
    @include lib-icon-size-18;
  }

  .lib-icon.lib-icon-size-xlg-14 {
    @include lib-icon-size-14;
  }
}
