.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  @include center-children('y');

  padding-top: 4px;
  padding-bottom: 4px;
  height: 56px;
  background-color: #fff;

  @supports (backdrop-filter: none) {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.7);
  }

  @supports (-webkit-backdrop-filter: none) {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.logo {
  display: inline-block;
  height: 100%;

  img {
    height: 100%;
  }
}

.nav-container {
  display: none;

  .nav-item {
    margin-right: 48px;
    width: 100%;

    .nav-link {
      @include lib-txt-4;
      @include lib-txt-weight-600;

      padding: 0;
      width: 100%;
      cursor: pointer;
      color: var(--lib-color-title);
      white-space: nowrap;

      &:hover {
        color: var(--lib-color-primary);
      }
    }

    .nav-link-special {
      @include lib-txt-4;
      @include lib-txt-weight-600;

      padding: 0;
      cursor: pointer;
      color: var(--lib-color-title);
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
        color: var(--lib-color-primary);
      }
    }
  }
}

.nav-item:last-child {
  margin: 0;
}

.download {
  display: none;
}

.side-menu-toggle,
.side-menu-close {
  @include center-children('y');

  margin-left: auto;
  padding: 10px;
  cursor: pointer;

  &:hover {
    border-radius: 24px;
    background: var(--lib-color-surface-3);
    padding: 10px;
  }
}

.side-menu-toggle[aria-expanded=true] {
  display: none;
}

.side-menu-close.collapsed,
.side-menu-close[aria-expanded=false] {
  display: none;
}

@media (min-width: 768px) {
  //
}

@media (min-width: 1200px) {
  .nav-container {
    @include center-children('y');
  }

  .download {
    display: block;
    margin-left: auto;
  }

  .side-menu-toggle,
  .side-menu-close {
    display: none;
  }
}
@media (min-width: 1366px) {
  .nav-container {
    margin-left: 96px;
    margin-right: 48px;
    .nav-item {
      margin-right: 36px;
      width: 100%;
    }
  }
}
@media (min-width: 1900px) {
  .nav-container {
    margin-left: 225px;
    margin-right: 225px;
    .nav-item {
      margin-right: 48px;
      width: 100%;
    }
  }
}

@media (min-width: 2560px) {
  .nav-container {    
    .nav-item {
      margin-right: 96px;
      width: 100%;
    }
  }
}