.rating-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .rating-liked {
        display: flex;
        flex-direction: row;
        //width: 70px;
        padding-right: 10px;
        height: 100%;
        //order: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--lib-color-dark-500);

        &>svg {
            color: var(--lib-color-dark-500);
        }

        &__number {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            margin-left: 5px;
        }
    }

    .rating-disliked {
        display: flex;
        flex-direction: row;
        //width: 70px;
        height: 100%;
        //order: 2;
        margin-left: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--lib-color-dark-500);

        &>svg {
            color: var(--lib-color-dark-500);
        }

        &__number {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            margin-left: 5px;
        }
    }
}

.rating-progressbar {
    width: 100%;

    &__green {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 2px;
        height: 24px;
        background: #E0FAEA;
        flex: none;
        align-self: stretch;
        flex-grow: 1;

        &__text {
            height: 12px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #11803D;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        &__line {
            width: 100%;
            height: 2px;
            background: #00B94A;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
    }

    &__red {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 2px;
        height: 24px;
        background: #FFEEEE;
        flex: none;
        align-self: stretch;
        flex-grow: 1;

        &__text {
            height: 12px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #B62828;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        &__line {
            width: 100%;
            height: 2px;
            background: #EA4646;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
    }

    &__yellow {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 2px;
        height: 24px;
        background: #FFFBD9;
        flex: none;
        align-self: stretch;
        flex-grow: 1;

        &__text {
            height: 12px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #6D6200;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        &__line {
            width: 100%;
            height: 2px;
            background: #EEDE51;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
    }

    &__gray {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 2px;
        height: 24px;
        //background: #E0FAEA;
        flex: none;
        align-self: stretch;
        flex-grow: 1;

        &__text {
            height: 12px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #A9B6C3;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        &__line {
            width: 100%;
            height: 2px;
            background: #D3DDE7;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
    }
}