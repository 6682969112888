.libitems-download-page-wrapper {
    display: flex;    
    width: auto;
    flex-direction: column;
    min-height: calc(100% - 468px);

    .libitems-download-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;
        width: auto;

        &__list-os {
            display: flex;
            flex-direction: column;            
            height: auto;            
            order: 1;
            padding: 0 1rem;

            input {
                @include lib-txt-4;
                @include lib-txt-weight-600;
                
                border-radius: 0;
                border:0;
                border: 1px solid var(--lib-color-dark-100);
                background: var(--lib-color-surface-1);
                padding: 0.5rem;
                padding-bottom: 9px;  // prevents jumping of the input field
                
                &:focus {
                  color: var(--lib-color-dark-700);
                
                  border-bottom: 2px solid var(--lib-color-denim-500);
                  outline: none;        
                  border-radius: 0;
                  padding-bottom: 8px; // prevents jumping of the input field                
                  box-shadow: none; 
                }
              }
            
            &.not-visible {
                visibility: hidden;
                height: 0;
                padding: 0;
            }

            &.lib-spinner {
                width: auto;
            }

            &__title {
                width: 100%;
                height: 50px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 110.52%;
                letter-spacing: -0.05em;
                color: var(--lib-color-dark-700);
            }


            &__type-os {
                width: 100%;
                height: 50px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.05em;
                color: var(--lib-color-dark-500);
                padding: 10px 10px;
            }

            &__list-install{
                

            &__install {
                display: flex;
                flex-direction: row;
                padding: 10px 10px;
                background: var(--lib-color-surface-1);
                border: 1px solid var(--lib-color-dark-100);
                margin-bottom: 10px;
                align-items: baseline;
                
                &__type {
                    width: 200px;
                    height: 48px;
                    order: 0;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: var(--lib-color-dark-300);
                    padding: 12px 12px;
                }                

                &__size {
                    width: 200px;
                    height: 48px;
                    order: 1;                    
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--lib-color-dark-200);
                    text-align: center;
                    padding: 12px 12px;
                }

                &__bth {
                    width: auto;
                    height: 48px;
                    order: 2;
                    align-items: center;
                    justify-content: center;                    
                }
            }

            &__available-registrations,
            &__not-available-registrations {
                display: flex;
                flex-direction: row;
                padding: 0;
                align-items: baseline;                

                .aval-text {
                    width: 100%;
                    order: 0;                    
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.125rem;
                    color: var(--lib-color-dark-300);
                    padding: 0.5rem 0;
                }
                .aval-num {
                    width: 200px;
                    order: 1;                    
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--lib-color-dark-200);
                    text-align: center;
                    padding: 0.5rem 0.5rem;
                }

                span {
                    color: var(--lib-color-dark-500);
                    font-weight: 600;
                }
                a {
                    color: var(--lib-color-denim-500);
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        }

        &__logo-app {
            height: 486px;
            order: 0;

            img {
                max-width: 100%;
            }

        }

        .news-container{
            height: 270px;
            border: none;
        }


    }
}


@media (min-width: 768px) {
    .libitems-download-page-wrapper {
        .libitems-download-page-content {
            &__list-os {
                width: 570px;
                padding: 0;
            }

            &__logo-app {
                width: 486px;
                
                img {
                    width: 465px;
                }
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .libitems-download-page-wrapper {
        .libitems-download-page-content {
            &__list-os {}

            &__logo-app {}
        }
    }
}

@media screen and (min-width: 1900px) {
    .libitems-download-page-wrapper {
        .libitems-download-page-content {
            &__list-os {}

            &__logo-app {}
        }
    }
}

@media screen and (min-width: 2560px) {
    .libitems-download-page-wrapper {
        .libitems-download-page-content {
            &__list-os {}

            &__logo-app {}
        }
    }
}

@media screen and (min-width: 3200px) {
    .libitems-download-page-wrapper {
        .libitems-download-page-content {
            &__list-os {}

            &__logo-app {}
        }
    }
}